@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--main-text-color);
  position: relative;
  background: var(--main-background-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  background: var(--nav-background-color);
  width: 100%;
  height: var(--nav-height);
  transition: height 0.2s ease-in-out;
  margin-bottom: 60px;
  border-bottom: 1px solid #fff;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 900px;
  margin: auto;
}

.nav-logo {
  width: var(--nav-logo-width);
  object-fit: scale-down;
  transform: translateY(5px);
}

nav a {
  font-size: var(--nav-logo-font-size);
  color: var(--nav-link-color);
  text-decoration: none;
}

nav a.twitter {
  margin-right: 10px;
}

nav a.discord {
  width: 35px;
}

nav a:hover {
  color: var(--nav-link-hover-color);
  background-color: var(--nav-link-hover-background-color);
}

nav .nav-social svg {
  width: 100%;
  height: 100%;
}

.mobile-menu-button {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 37px;
  right: -50px;
  transition: right 0.2s ease-in-out;
  z-index: 45;
  cursor: pointer;
}

.open-menu {
  left: 0px !important;
}

.mobile-nav {
  position: fixed;
  min-height: 100vh;
  background: var(--mobile-nav-background-color, rgb(117, 69, 69));
  left: -100%;
  z-index: 50;
  width: 100%;
  transition: left 0.3s ease;
}

.mobile-nav-logo {
  max-width: 200px;
}

.mobile-nav-close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.mobile-nav-close-button img {
  height: 100%;
  width: 100%;
}

.mobile-nav ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 40px;
  list-style: none;
}

.mobile-nav li {
  list-style: none;
  padding: 20px;
}

.mobile-nav a {
  text-decoration: none;
  color: var(--mobile-nav-link-color);
  font-size: var(--mobile-nav-link-size);
}

.mobile-nav a:hover {
  color: var(--mobile-nav-link-hover-color);
}

.mobile-nav-logo {
  height: var(--nav-logo-height);
}

.text-muted {
  color: darkgrey;
}

.nav-social {
  width: 40px;
}

.social-icons {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .hide-800 {
    display: none;
  }

  .mobile-menu-button {
    right: 37px;
  }

  .nav-logo {
    height: 100px !important;
  }

  nav {
    height: 100px;
  }
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

header {
  max-width: 900px;
  margin: auto;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 40px;
  display: grid;
  gap: 40px;
  padding: 35px;
  flex-direction: column;
}

.mintHeader {
  flex-direction: column;
  gap: 20px;
}

@media only screen and (min-width: 800px) {
  header {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    gap: 20px;
  }
}

.text-primary-color {
  color: var(--text-primary-color);
}

.text-secondary-color {
  color: var(--text-secondary-color);
}

.team {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.team-item {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px -13px white;
}

.faq {
  max-width: 900px;
  margin: auto;
  align-items: center;
  padding: 20px;
}

.faq div {
  margin-bottom: 20px;
}

.faq p {
  padding: 20px;
}

.rainbow-gallery {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.rainbow-gallery img {
  width: 100%;
}

.card {
  align-items: center;
  border-radius: var(--card-border-radius);
  padding: 40px;
  background: var(--card-background-color);
  box-shadow: var(--card-shadow);
}

.container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.content-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 900px;
  margin: auto;
  box-sizing: border-box;
}

.mints-available {
  background: rgb(65, 65, 65);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-right {
  display: flex;
  justify-content: center;
}

.minting-right img {
  max-width: 300px;
  border-radius: 10px;
  border: 1px solid #fff;
  object-fit: scale-down;
}

.minting-box {
  background-color: var(--minting-box-color) !important;
  color: var(--minting-box-text-color) !important;
}

.minting-box h5 {
  color: var(--minting-box-heading-color);
}

.minting-info {
  background-color: var(--minting-box-info-box-color);
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.minting-info h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

.minting-button {
  background: var(--minting-button-color) !important;
}

.custom-mint-container {
  margin-bottom: 20px;
}

.loading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

.top-card {
  background: #000;
  color: #fff;
}

.prize {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.prize > div {
  flex: 0 0 50%;
  background-color: #000;
  margin: 10px;
}

.prize-pool-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prize-pool-wrapper .card {
  max-width: 300px;
  background: linear-gradient(
    140deg,
    rgba(255, 99, 132, 0.2) 0%,
    rgba(54, 162, 235, 0.2) 50%,
    rgba(255, 206, 86, 0.2) 75%
  );
}
.prize-pool-wrapper span {
  font-size: 18px;
  margin-top: 10px;
  display: block;
}

.prize-distribution-wrapper {
  padding: 15px;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
  color: #fff;
}

.prize-distribution {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}

.prize-distribution h4 {
  margin-bottom: 10px;
  color: #fff;
}

.faq-row-wrapper {
  border-radius: 10px;
}

.faq-row-wrapper * {
  color: #fff !important;
}

.faq-row-wrapper .faq-row {
  margin: 20px 0px;
  background: #000;
  border-radius: 10px;
}

.faq-row-wrapper .row-title {
  font-weight: 600;
  color: #fff !important;
}

.faq-row-wrapper .row-title,
.faq-row-wrapper h2 {
  padding-left: 10px !important;
}

footer {
  padding: 25px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  border-top: 1px solid #fff;
  font-family: "Kaushan Script", "Roboto";
}

footer > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .divider {
  margin: 0px 5px;
}

footer .logo {
  max-width: 80px;
  transform: translateY(5px);
}

footer .powered-by-solana {
  max-width: 120px;
}

@media only screen and (max-width: 920px) {
  .nav-container {
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 520px) {
  .prize {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
  .prize-distribution-wrapper {
    max-width: 100%;
    width: 100%;
    border-radius: 0px;
  }
  .prize-distribution {
    max-width: 100%;
    width: 100%;
  }
  .prize > div {
    flex: 0 0 100%;
  }
  .minting-right {
    height: 250px;
  }
  .minting-right img {
    max-width: 250px;

    border: none;
  }
  .top-card {
    border-radius: 0px;
  }
  body {
    overflow-x: hidden;
  }
  .nav-social {
    width: 35px;
  }
  .nav-social.discord {
    width: 30px;
  }
  .nav-container {
    padding-left: 0px;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
